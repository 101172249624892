import { Component, OnInit } from '@angular/core';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuController } from '@ionic/angular';

import { Elurisoft } from 'src/providers/elurisoft';
import { Account } from 'src/providers/account';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
	constructor(
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private menu: MenuController,
		private elurisoft: Elurisoft,
		public account: Account
	) {
		if (window !== window.parent) {
			document.querySelector('html').classList.add('is_iframe');
		}
	}

	async ngOnInit() {
		await this.initializeApp();
	}

	async initializeApp() {
		this.splashScreen.show();
		this.elurisoft.platform.ready().then(() => {
			let page = '';
			const pageSegments = window.location.pathname.split('/');
			if (pageSegments.length > 0) {
				if (pageSegments[0] == '') {
					pageSegments.shift();
				}
				if (pageSegments.length > 0) {
					page = pageSegments[0];
				}
			}
			if (page != 'login_as') {
				this.account.initialize().then(() => {
					if (this.account.userDetails && this.account.userDetails.auth_token) {
						this.account.verifyAuthToken(this.account.userDetails.id, this.account.userDetails.auth_token).then(async (result: any) => {
							if (result.success === 1) {
								if(+result.data.userdata.is_accepted_term_and_conditions == 1) {
									this.account.getAdminUsers();
									this.account.isAcceptedTermsAndConditions = true;
									this.account.userDetails.is_accepted_term_and_conditions = 1;
									await this.elurisoft.storage.set('userDetails', result.data.userdata);
									if (['/', '/login', '/login/reset-password'].indexOf(this.elurisoft.router.url) >= 0) {
										this.elurisoft.navigate('/tasks');
									}
								} else {
									this.account.isAcceptedTermsAndConditions = false;
									this.account.userDetails.is_accepted_term_and_conditions = 0;
									await this.elurisoft.storage.set('userDetails', result.data.userdata);
									this.elurisoft.navigate('/term-and-conditions');
								}
							} else {
								this.elurisoft.storage.set('userDetails', null);
								this.elurisoft.navigate('/login');
							}
						}).catch(() => {
							this.elurisoft.storage.set('userDetails', null);
							this.elurisoft.navigate('/login');
						});
					} else {
						this.elurisoft.storage.set('userDetails', null);
						this.elurisoft.navigate('/login');
					}
				});
			}
			this.statusBar.styleDefault();
			this.splashScreen.hide();
		});
	}

	menuItemClicked(item) {
		this.menu.close();
		if (item == 'logout') {
			this.account.doLogout();
		} else {
			this.elurisoft.navigate('/' + item);
		}
	}
}
