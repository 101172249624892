import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BarcodeFormat } from '@zxing/library';
import { BehaviorSubject } from 'rxjs';


@Component({
	selector: 'app-barcode-scan',
	templateUrl: './barcode-scan.component.html',
	styleUrls: ['./barcode-scan.component.scss'],
})
export class BarcodeScanComponent implements OnInit {

	@ViewChild('scanner', { static: false })
	scanner!: ZXingScannerComponent;

	availableDevices: MediaDeviceInfo[];
	deviceCurrent: MediaDeviceInfo;
	deviceSelected: string;

	formatsEnabled: BarcodeFormat[] = [
		BarcodeFormat.UPC_A,
		BarcodeFormat.EAN_8,
		BarcodeFormat.EAN_13,
	];

	hasDevices: boolean;
	hasPermission: boolean;

	qrResultString: string;

	torchEnabled = false;
	torchAvailable$ = new BehaviorSubject<boolean>(false);
	tryHarder = true;

	constructor(private modalCtrl: ModalController) { }

	ngOnInit() { }

	cancel() {
		return this.modalCtrl.dismiss(null, 'cancel');
	}

	clearResult(): void {
		this.qrResultString = null;
	}

	onCamerasFound(devices: MediaDeviceInfo[]): void {
		this.availableDevices = devices;
		this.hasDevices = Boolean(devices && devices.length);
	}

	onCodeResult(resultString: string) {
		this.qrResultString = resultString;

		if (this.qrResultString != null && this.qrResultString != '') {
			return this.modalCtrl.dismiss(this.qrResultString, 'confirm');
		}
	}

	onDeviceSelectChange(selected: string) {
		const selectedStr = selected || '';
		if (this.deviceSelected === selectedStr) { return; }
		this.deviceSelected = selectedStr;
		const device = this.availableDevices.find(x => x.deviceId === selected);
		this.deviceCurrent = device || undefined;
	}

	onDeviceChange(device: MediaDeviceInfo) {
		const selectedStr = device?.deviceId || '';
		if (this.deviceSelected === selectedStr) { return; }
		this.deviceSelected = selectedStr;
		this.deviceCurrent = device || undefined;
	}

	onHasPermission(has: boolean) {
		this.hasPermission = has;
	}

	onTorchCompatible(isCompatible: boolean): void {
		this.torchAvailable$.next(isCompatible || false);
	}

	toggleTorch(): void {
		this.torchEnabled = !this.torchEnabled;
	}

}
