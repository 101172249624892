import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: 'login',
		loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
	},
	{
		path: 'login_as',
		loadChildren: () => import('./pages/login_as/login_as.module').then(m => m.LoginAsPageModule)
	},
	{
		path: 'profile',
		loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
	},
	{
		path: 'tasks',
		loadChildren: () => import('./pages/tasks/tasks.module').then(m => m.TasksPageModule)
	},
	{
		path: 'task-details',
		loadChildren: () => import('./pages/task-details/task-details.module').then(m => m.TaskDetailsPageModule)
	},
	{
		path: 'threads',
		loadChildren: () => import('./pages/threads/threads.module').then(m => m.ThreadsPageModule)
	},
	{
		path: 'contacts',
		loadChildren: () => import('./pages/contacts/contacts.module').then(m => m.ContactsPageModule)
	},
	{
		path: 'ingredients',
		loadChildren: () => import('./pages/ingredients/ingredients.module').then(m => m.IngredientsPageModule)
	},
	{
		path: 'add-edit-task',
		loadChildren: () => import('./pages/add-edit-task/add-edit-task.module').then(m => m.AddEditTaskPageModule)
	},
	{
		path: 'term-and-conditions',
		loadChildren: () => import('./pages/term-and-conditions/term-and-conditions.module').then(m => m.TermAndConditionsPageModule)
	},


];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
