import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpModule } from '@angular/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { Elurisoft } from '../providers/elurisoft';
import { Account } from '../providers/account';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { BarcodeScanComponent } from './components/barcode-scan/barcode-scan.component';
import { BarcodeScanner2Component } from './components/barcode-scanner2/barcode-scanner2.component';
import { NewIngredientComponent } from './components/new-ingredient/new-ingredient.component';

@NgModule({
	declarations: [AppComponent, BarcodeScanComponent, BarcodeScanner2Component, NewIngredientComponent],
	entryComponents: [],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		IonicStorageModule.forRoot({
			name: '_main_db',
			driverOrder: ['localstorage', 'indexeddb', 'sqlite', 'websql'],
		}),
		AppRoutingModule,
		HttpModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
		ZXingScannerModule
	],
	providers: [
		StatusBar,
		SplashScreen,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		Storage,
		Elurisoft,
		Account
	],
	bootstrap: [AppComponent],
	exports: [BarcodeScanComponent, BarcodeScanner2Component, NewIngredientComponent]
})
export class AppModule { }
