import { Injectable } from '@angular/core';
import { Http, URLSearchParams, Headers, RequestOptions, Response } from '@angular/http';
import { FormControl } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { timeout } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from './../environments/environment';

import { Platform, AlertController, ToastController, LoadingController } from '@ionic/angular';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class Elurisoft {

	public config = environment;

	private isLoaderShowing: Boolean = false;
	private loader: any;

	public appIsOnline$: Observable<boolean>;

	constructor(
		public router: Router,
		public location: Location,
		public platform: Platform,
		public http: Http,
		public storage: Storage,
		public alertCtrl: AlertController,
		private toastCtrl: ToastController,
		private loadingCtrl: LoadingController
	) {
		this.platform.ready().then(() => {
			this.initConnectivityMonitoring();
		});
	}

	private initConnectivityMonitoring() {
		if (!window || !navigator || !('onLine' in navigator)) return;
		this.appIsOnline$ = merge(
			of(null),
			fromEvent(window, 'online'),
			fromEvent(window, 'offline')
		).pipe(map(() => navigator.onLine))
	}

	async alert(message: string, header: string = '', subHeader: string = '') {
		const alertOptions: any = {};
		alertOptions.header = header;
		alertOptions.subHeader = subHeader;
		alertOptions.message = message;
		alertOptions.buttons = [{
			text: 'OK',
			role: 'cancel'
		}];
		const alert = await this.alertCtrl.create(alertOptions);
		alert.present();
	}

	async toast(title: string = '', message: string, duration: any = 5000, button: any = 'OK') {
		const toastOptions: any = {};
		if (message === undefined) {
			message = 'Something went wrong. Try again later.';
		}
		title += (title !== '') ? '\n' : '';
		toastOptions.position = 'bottom';
		toastOptions.message = title + message;
		if (button !== false) {
			toastOptions.buttons = [{
				text: button,
				role: 'cancel'
			}];
		}
		if (duration !== false) {
			toastOptions.duration = duration;
		}
		const toast = await this.toastCtrl.create(toastOptions);
		toast.present();
	}

	async showLoader(message = 'Please wait...') {
		if (!this.isLoaderShowing && !this.loader) {
			this.isLoaderShowing = true;
			const loaderOptions: any = {};
			loaderOptions.message = message;
			this.loader = await this.loadingCtrl.create(loaderOptions);

			await this.loader.present().then(() => { }).catch(() => {

			});
		}

		return Promise.resolve();
	}

	async hideLoader() {
		if (this.isLoaderShowing && this.loader) {
			await this.loader.dismiss().then(() => {
				this.isLoaderShowing = false;
				this.loader = null;
			}).catch(() => {
				this.loader = null;
			});
		}
		return Promise.resolve();
	}

	isEmptyObj(obj: any = {}) {
		return (obj === null || typeof obj !== 'object' || Object.keys(obj).length === 0);
	}

	isValidJSON(strJSON: string) {
		try {
			JSON.parse(strJSON);
		} catch (e) {
			return false;
		}
		return true;
	}

	objectToFormData(obj: object, form?: FormData, namespace?: string): FormData {
		const formData = form || new FormData();
		for (const property in obj) {
			if (!obj.hasOwnProperty(property)) { // (|| !obj[property])
				continue;
			}
			const formKey = namespace ? `${namespace}[${property}]` : property;
			if (obj[property] instanceof Date) {
				formData.append(formKey, obj[property].toISOString());
			} else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
				this.objectToFormData(obj[property], formData, formKey);
			} else {
				formData.append(formKey, obj[property]);
			}
		}
		return formData;
	}

	makeHttpRequest(apiEndPoint, params: any = {}, method = 'post', timeoutInterval: number = (1000 * 60 * 60), headers: any = {}): Promise<any> {
		if (!headers || !headers.Authorization || headers.Authorization === '') {
			headers.Authorization = this.config.APIAccessToken;
		}

		const data = new URLSearchParams();
		for (const name in params) {
			if (params.hasOwnProperty(name)) {
				data.append(name, params[name]);
			}
		}

		const dataQS = data.toString();
		if (method === 'get') {
			return this.http.get(this.config.APIurl + apiEndPoint + '?' + dataQS).toPromise().then((response) => {
				const responseJson = response.json();
				return Promise.resolve(responseJson || '');
			}).catch((error) => {
				return Promise.reject(this.getHttpError(error));
			});
		}
		else if (method === 'post') {
			// headers['Content-Type'] = 'multipart/form-data';
			const httpHeaders = new Headers(headers);
			const httpOptions = new RequestOptions({ headers: httpHeaders });

			const reqParams = this.objectToFormData(params);
			return this.http.post(this.config.APIurl + apiEndPoint, reqParams, httpOptions).pipe(timeout(timeoutInterval)).toPromise().then((response) => {
				const responseJson = response.json();
				return Promise.resolve(responseJson || '');
			}, (handler) => {
				return Promise.reject(this.getHttpError(handler));
			}).catch((error) => {
				return Promise.reject(this.getHttpError(error));
			});
		}
		else {
			return Promise.reject(this.getHttpError({}));
		}
	}

	pad(num: number, size: number = 1) {
		let str: string = num + '';
		while (str.length < size) {
			str = '0' + str;
		}
		return str;
	}

	getArrayIndex(array, key, value) {
		for (let i = 0; i < array.length; i++) {
			if (array[i][key] === value) {
				return i;
			}
		}
		return false;
	}

	getHttpError(error: Response | any) {
		let errMsg = 'Invalid Request!';
		if (error instanceof Response) {

			if (this.getObjVal(error, ['status']) === 0) {
				errMsg = 'Failed to connect to server.';
			}
			else {
				errMsg = `HTTP_ERROR ${this.getObjVal(error, ['status'], '')} : ${this.getObjVal(error, ['statusText'], errMsg)}`;
			}
		} else {
			if (this.getObjVal(error, ['status']) === 0) {
				errMsg = 'Failed to connect to server.';
			}
			else {
				errMsg = `HTTP_ERROR ${this.getObjVal(error, ['status'], '')} : ${this.getObjVal(error, ['statusText'], errMsg)}`;
			}
		}
		error.errorMessage = errMsg;
		return error;
	}

	getObjVal(obj, properties = [], defaultVal: any = '') {
		if (this.isEmptyObj(obj)) {
			return defaultVal;
		}
		else {
			if (properties.length >= 1) {
				const property = properties.shift();
				if (typeof obj[property] === 'undefined' || obj[property] == null) {
					return defaultVal;
				}
				else {
					if (properties.length > 0) {
						return this.getObjVal(obj[property], properties, defaultVal);
					}
					else {
						return obj[property];
					}
				}
			}
			else {
				return defaultVal;
			}
		}
	}

	getValidationErrors(form: any, validationMessages: any): any {
		if (form && Object.keys(validationMessages).length > 0) {
			for (const field in validationMessages) {
				if (validationMessages.hasOwnProperty(field)) {
					validationMessages[field].error = '';
					const control = form.get(field);
					if (control && control.enabled && (control.dirty || control.touched) && control.invalid) {
						const messages = validationMessages[field];
						for (const key in control.errors) {
							if (control.errors.hasOwnProperty(key)) {
								validationMessages[field].error += (validationMessages[field].error !== '') ? '<br/>' : '';
								validationMessages[field].error += messages[key];
								break;
							}
						}
					}
				}
			}
		}
		return validationMessages;
	}

	navigate(page: any, params: any = []) {
		params = (Array.isArray(params)) ? params : [params];
		params.unshift(page);
		this.router.navigate(params);
	}

	navigateBack() {
		this.location.back();
	}

	ucFirst(str: string = '') {
		str = (str.length > 0) ? str.charAt(0).toUpperCase() + str : str;
		str = (str.length > 1) ? str.charAt(0) + str.slice(2) : str;
		return str;
	}

	normalizeString(str: string = '') {
		return str.replace(/[-_]/g, ' ');
	}

	initConfig() {
		this.storage.get('config').then((config) => {
			if (!this.isEmptyObj(config)) {
				this.config = config;
			}
			else {
				this.config = environment;
			}
		});
	}

	updateConfig(config: any) {
		return new Promise((resolve, reject) => {
			this.hideLoader();
			config = Object.assign(this.config, config);

			this.storage.set('config', config).then(() => {
				this.initConfig();
				resolve(config);
			}).catch((ex) => {
				reject(ex);
			});
		});
	}

	clearConfig() {
		return new Promise((resolve, reject) => {
			this.storage.remove('config').then(() => {
				this.initConfig();
				resolve(true);
			}).catch((ex) => {
				reject(ex);
			});
		});
	}

	timeAgo(ts) {
		const currTS = Math.round(Date.parse(new Date().toUTCString()) / 1000);
		let difference = Math.floor(currTS - ts);
		const periods = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year', 'decade'];
		const lengths = [60, 60, 24, 7, 4.35, 12, 10];
		let j;
		for (j = 0; difference >= lengths[j]; j++) {
			difference /= lengths[j];
		}
		difference = Math.floor(difference);
		if (difference !== 1) {
			periods[j] += 's';
		}
		if (difference <= 10 && periods[j].indexOf('second') === 0) {
			return 'Just now';
		} else {
			return difference + ' ' + periods[j] + ' ago';
		}
	}
	timestamp() {
		return Math.round(Date.parse(new Date().toUTCString()) / 1000);
	}
	tSecFromObj(dateObject: Date) {
		return (dateObject.getHours() * 3600) + (dateObject.getMinutes() * 60) + (dateObject.getSeconds());
	}
	tDuration(totalSecs: string | number = '0') {
		totalSecs = totalSecs.toString();
		const secNum = parseInt(totalSecs, 10); // don't forget the second param
		const hours = Math.floor(secNum / 3600);
		const minutes = Math.floor((secNum - (hours * 3600)) / 60);
		const seconds = secNum - (hours * 3600) - (minutes * 60);
		return this.pad(hours, 2) + ':' + this.pad(minutes, 2) + ':' + this.pad(seconds, 2);
	}
	tFormatFromStr(time: string | any[]) {
		time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
		if (time.length > 1) {
			time = time.slice(1, -1);
			time[3] = +time[0] < 12 ? ' AM' : ' PM';
			time[0] = +time[0] % 12 || 12;
		}
		time[0] = this.pad(+time[0], 2);
		time[2] = this.pad(+time[2], 2);
		return time.join('');
	}
	dFormatFromStr(strDate = '0000-00-00', dateSeparator = '-', defaultVal = 'N/A') {
		if (strDate !== '0000-00-00') {
			const objDate = new Date(strDate);
			const formattedDate = this.pad((objDate.getUTCMonth() + 1), 2) + dateSeparator + this.pad(objDate.getUTCDate(), 2) + dateSeparator + objDate.getUTCFullYear();
			return formattedDate;
		}
		return defaultVal;
	}
	dtFormatFromStr(strDateTime = '0000-00-00 00:00:00', dateSeparator = '-', defaultVal = 'N/A') {
		let formattedDateTime = defaultVal;
		if (strDateTime !== '0000-00-00 00:00:00') {
			const arrDateTime = strDateTime.split(' ');
			if (arrDateTime.length === 2) {
				formattedDateTime = this.dFormatFromStr(arrDateTime[0], dateSeparator) + ' ' + this.tFormatFromStr(arrDateTime[1]);
			}
		}
		return formattedDateTime;
	}
	dtFormatFromObj(objDate: Date) {
		return this.dtFormatFromStr(this.dtFormatToDB(objDate));
	}
	dtFormatFromStrToObj(strDateTime: string) {
		return new Date(Date.parse(strDateTime.replace(/[-]/g, '/')));
	}
	dFormatToDB(objDate: Date | string) {
		let strDate = '';
		if (objDate instanceof Date || (typeof objDate === 'string' && objDate.split(' ').join('') !== '')) {
			objDate = (objDate instanceof Date) ? objDate : new Date(objDate);
			if (objDate instanceof Date) {
				strDate += objDate.getFullYear() + '-' + this.pad((objDate.getMonth() + 1), 2) + '-' + this.pad(objDate.getDate(), 2);
			}
		}
		return strDate.trim();
	}
	tFormatToDBFrom24HoursFormat(time: string) {
		let hours = Number(time.match(/^(\d+)/)[1]);
		const minutes = Number(time.match(/:(\d+)/)[1]);
		const AMPM = time.match(/\s(.*)$/)[1];
		if (AMPM === 'PM' && hours < 12) {
			hours = hours + 12;
		}
		if (AMPM === 'AM' && hours === 12) {
			hours = hours - 12;
		}
		let sHours = hours.toString();
		let sMinutes = minutes.toString();
		if (hours < 10) {
			sHours = '0' + sHours;
		}
		if (minutes < 10) {
			sMinutes = '0' + sMinutes;
		}
		const sTime = sHours + ':' + sMinutes;
		return sTime;
	}
	tFormatToDB(objTime: Date | string) {
		let strTime = '';
		if (objTime instanceof Date || (typeof objTime === 'string' && objTime.split(' ').join('') !== '')) {
			objTime = (objTime instanceof Date) ? objTime : new Date(objTime);
			if (objTime instanceof Date) {
				strTime += this.pad(objTime.getHours(), 2) + ':' + this.pad(objTime.getMinutes(), 2) + ':' + this.pad(objTime.getSeconds(), 2);
			}
		}
		return strTime.trim();
	}
	dtFormatToDB(objDate: Date | string, objTime: Date | boolean = false) {
		let strDateTime = '';
		if (objDate instanceof Date || (typeof objDate === 'string' && objDate.split(' ').join('') !== '')) {
			objDate = (objDate instanceof Date) ? objDate : new Date(objDate);
			strDateTime = this.dFormatToDB(objDate) + ' ';
			if (objTime instanceof Date) {
				strDateTime += this.tFormatToDB(objTime);
			} else {
				strDateTime += this.tFormatToDB(objDate);
			}
		}
		return strDateTime.trim();
	}

	showMap(latitude, longitude) {

		const destination = latitude + ',' + longitude;
		if (this.config.deviceType === 'ios') {
			window.open('maps://?q=' + destination, '_system');
		} else {
			// android
			const label = encodeURI('Service Location');
			window.open('geo:0,0?q=' + destination + '(' + label + ')', '_system');
		}
	}

}

export class ElurisoftValidators {

	static email(c: FormControl) {
		const EMAIL_REGEXP = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;

		return (c.value === '' || EMAIL_REGEXP.test(c.value)) ? null : {
			email: {
				valid: false
			}
		};
	}

	static equalTo(matchControl: string) {
		let control1: FormControl;
		let control2: FormControl;
		return function matchOtherValidate(control: FormControl) {
			if (!control.parent) {
				return null;
			}
			if (!control1) {
				control1 = control;
				control2 = control.parent.get(matchControl) as FormControl;
				if (!control2) {
					throw new Error('equalToValidator(): Second control is not found in parent group');
				}
				control2.valueChanges.subscribe(() => {
					control1.updateValueAndValidity();
				});
			}
			if (!control2) {
				return null;
			}
			if (control2.value !== control1.value) {
				return {
					equalTo: {
						valid: false
					}
				};
			}
			return null;
		};
	}

	static slug(c: FormControl) {
		const USERNAME_REGEXP = /^[a-z0-9_-]+$/i;

		return USERNAME_REGEXP.test(c.value) ? null : {
			slug: {
				valid: false
			}
		};
	}

	static strongPassword(c: FormControl) {
		return (
			c.value === '' || (
				/^[A-Za-z0-9\d`\-=\\\/;',.~!@#$%^&*()_+{}|:"<>?]*$/.test(c.value) // consists of only these
				&& /[A-Z]/.test(c.value) // has an uppercase letter
				&& /[a-z]/.test(c.value) // has a lowercase letter
				&& /\d/.test(c.value) // has a digit
				&& /[`\-=\\\/;',.~!@#$%^&*()_+{}|:"<>?]/.test(c.value) // has a symbol
			)
		) ? null : {
			strongPassword: {
				valid: false
			}
		};
	}

}